import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
//import Footer from './components/Footer/Footer';

import Homepage from "../src/webPages/Homepage/Homepage";
import Partners from "../src/webPages/Partners/Partners";
import PreviousEditions from "./webPages/PreviousEditions/PreviousEditions";
import Internships from "./webPages/Internships/Internships";
import Testemunhos from "./components/Testemunhos/Testemunhos";

//import das imagens das empresas para usar nos partners
import altice from "../src/caroussel-logos/alticeOnly.png";
import accenture from "../src/caroussel-logos/accenture.png";
import adecco from "../src/caroussel-logos/Adecco.png";
import altri from "../src/caroussel-logos/altri.png";
import Celfinet from "../src/caroussel-logos/Celfinet.png";
import bluepharma from "../src/caroussel-logos/bluepharma.png";
import deloitte from "../src/caroussel-logos/deloitte.svg";
import dspa from "../src/caroussel-logos/dspa.png";
import enging from "../src/caroussel-logos/enging.png";
import framederop from "../src/caroussel-logos/framedrop.png";
import goodbarber from "../src/caroussel-logos/goodBarber.png";
import inovaAltice from "../src/caroussel-logos/inova-ria & altice labs.png";
import ipn from "../src/caroussel-logos/ipn.png";
import redlight from "../src/caroussel-logos/redlight.png";
import sisint from "../src/caroussel-logos/sisint.png";
import sodicentro from "../src/caroussel-logos/sodicentro.png";
import tuu from "../src/caroussel-logos/tuu.png";
import worten from "../src/caroussel-logos/worten.png";
// Imagens anos anteriores

import imagem1 from "./previousEditions-images/imagem1.svg";
import imagem2 from "./previousEditions-images/imagem2.svg";
import imagem3 from "./previousEditions-images/imagem3.svg";

// ----------------------

function App() {
  //aqui alterar quais são as areas que existem e nomes das empresas
  const filters = [
    {
      title: "AREAS",
      filters: [
        "Design e Multimédia",
        "Engenharia Biomédica",
        "Engenharia e Ciência de Dados",
        "Engenharia Eletrotécnica",
        "Engenharia Física",
        "Engenharia Mecânica",
      ],
    },

    {
      title: "DURAÇÃO",
      filters: [
        "1 mês",
        "Até 12 meses",
        "2 meses",
        "3 meses",
        "6 meses",
        "6 meses a 1 ano",
        "6 a 9 meses",
        "8 a 10 semanas",
        "9 meses",
        "9 a 12 meses",
        "12 meses",
      ],
    },

    {
      title: "TIPO",
      filters: [
        "Curricular",
        "Curricular ou Profissional",
        "Profissional",
        "Verão",
      ],
    },

    {
      title: "EMPRESAS",
      filters: [
        "Accenture",
        "Altice Labs - Inova Ria",
        "TUU",
        "Redlight",
        "Subvisual",
        "Brands&Ninjas",
      ],
    },
  ];

  const images = [{ image: imagem1 }, { image: imagem2 }, { image: imagem3 }];

  const people = [
    {
      nome: "Mónica Wolters",
      texto:
        "À parte de tudo o que aprendi a nível técnico, entendi como funciona uma empresa e todas as dinâmicas que são importantes para manter uma equipa de trabalho coesa e unida. Para além de uma incrível equipa, ganhei um grupo de amigos, com o qual conto ainda hoje!",
    },
    {
      nome: "Simão Nogueira",
      texto:
        "Para mim, o .works é um passo crucial na aprendizagem por que temos de passar para nos tornarmos os profissionais da área que ambicionamos ser.",
    },
    {
      nome: "Filipe Lopes",
      texto:
        "Aprendi imenso devido às pesquisas que fui fazendo para concluir o projecto, e com o feedback oferecido pelos meus coordenadores. Ambientei-me ao ritmo de trabalho do mundo real, que é bastante diferente do ritmo académico. Finalmente, ganhei também ligações com as pessoas da empresa.",
    },
    {
      nome: "Mariana Mugeiro",
      texto:
        "O .works é uma oportunidade única: muitas vezes sentimo-nos nervosos ou reticentes em ter entrevistas para estágios ou empregos, pela formalidade das ocasiões. O .works é o sítio perfeito para por esse nervosismo de lado! O ambiente descontraído e o modelo de flash interviews fazem com que toda esta experiência seja muito mais tranquila. Basta seres quem és, e com certeza terás uma surpresa agradável!",
    },
  ];

  const partners = [
    enging,
    inovaAltice,
    redlight,
    sodicentro,
    tuu,
    worten,
    dspa,
    framederop,
    goodbarber,
    ipn,
    sisint,
    Celfinet,
    bluepharma,
    altri,
    deloitte,
    adecco,
    accenture,
  ];

  // Testemunhos dos anos anteriores
  const testemunhos = [
    {
      ano: 2017,
      texto:
        "A segunda edição do .works decorreu no dia 31 de Março no Centro Cultural Dom Dinis, e contou com 90 candidatos numa tarde descontraída e informal, durante a qual os estudantes tiveram a oportunidade de conhecer as empresas e muitos conquistaram uma vaga num dos estágios oferecidos pelas 15 presentes! Além de proporcionarmos aos nossos estudantes um contacto com o seu futuro mercado de trabalho, houve ainda um coffee break onde todos os participantes puderam relaxar e descontrair.",
    },
    {
      ano: 2018,
      texto:
        "Em 2018, no dia 2 de Maio aconteceu a terceira edição do .works no Aqui Base Tango, em Coimbra. O evento foi dividido em duas partes, a primeira foi então o .works, onde os estudantes tiveram a oportunidade de contactar e comunicar com as empresas e startups presentes e a segunda, já no fim da tarde, consistiu num momento descontraído, no qual as empresas e os participantes tiveram a oportunidade de se juntar e trocar ideias. Durante o evento, e nos intervalos entre as entrevistas, foram dinamizadas algumas atividades como, jogos de tabuleiro, dj live set e diversos desafios para evitar o nervosismo que pudesse existir por parte dos nossos participantes.",
    },
    {
      ano: 2019,
      texto:
        "A última edição do evento (que pretende facilitar o contacto de estudantes universitários interessados em fazer um estágio de verão, curricular ou profissional junto de uma empresa), aconteceu no dia 24 de Abril de 2019 no Departamento de Engenharia Informática e no Departamento de Engenharia Eletrotécnica e de Computadores da Universidade de Coimbra. Contámos com 103 candidatos e com 15 empresas, muitas das quais possibilitaram estágios em mais do que uma área, abrangendo um maior número de estudantes. A planificação do evento manteve-se a mesma do ano anterior e o feedback final foi muito positivo. Não queiras perder a próxima edição e inscreve-te!",
    },
    {
      ano: 2022,
      texto:
        "Em 2022, o evento decorreu nos dias 11 e 18 de maio no Departamento de Engenharia Informática da Universidade de Coimbra, contando com 93 entrevistas e 14 empresas. Com cerca de 100 estágios, abrangimos estudantes das mais diversas áreas tecnológicas e obtivemos diversas mensagens positivas dos nossos participantes. Este ano temos mais surpresas para ti, não percas e inscreve-te!",
    },
    {
      ano: 2023,
      texto:
        "A 6ª edição do evento ocorreu no dia 10 de maio no Departamento de Engenharia Informática da Universidade de Coimbra. Esta edição contou com duas palestras oferecidas por duas empresas participantes. O evento teve a participação de mais de 15 empresas que ofereceram estágios em diversas áreas da tecnologia. A planificação do evento foi semelhante à do ano anterior, com algumas novas vantagens que foram bem recebidas por muitas das empresas participantes e pelos candidatos que, através desta edição, conseguiram estágios nas empresas que escolheram. Este ano, podes ser tu, não percas a oportunidade de estagiar na empresa que sempre sonhaste, inscreve-te!",
    },
    {
      ano: 2024,
      texto:
        "Em 2024, a 7ª edição do evento decorreu no dia 8 de maio, no Departamento de Engenharia Informática, contando com empresas de renome dentro e fora da cidade de Coimbra e dezenas de participantes. Com um feedback bastante positivo por parte das empresas e participantes, sentimos que o evento foi impactante para a comunidade académica e empresarial. Com vários estágios e bastante diversão, o .works é o evento ideal para planeares o teu futuro, inscreve-te!",
    },
  ];

  // [PERGUNTA, RESPOSTA] para FAQ
  const questions = [
    [
      "Como posso inscrever-me no .works?",
      "Para participar na 8ª edição do .works, é necessário realizar a inscrição através do Typeform disponível no nosso site. Neste formulário, terás a oportunidade de anexar o teu currículo, portanto, recomendamos que o mantenhas atualizado.",
    ],
    [
      "Posso inscrever-me no .works mesmo que não seja da área de estágio que me interessa ou da FCTUC?",
      "Embora o evento não esteja restrito à participação exclusiva de alunos das áreas anunciadas, os estágios são direcionados para essas áreas específicas. No entanto, se estiver interessado numa área diferente do teu curso, ainda podes inscrever-te! Não há restrições quanto ao teu curso de estudo ou instituição de origem.",
    ],
    [
      "Existe algum custo associado à inscrição no .works?",
      "Não, o .works é um evento gratuito que oferece aos estudantes a oportunidade de realizar entrevistas com as empresas que mais os interessam, visando a obtenção de futuros estágios.",
    ],
    [
      "Os estágios oferecidos são remunerados?",
      "A remuneração dos estágios depende da empresa que os oferece. Informações mais detalhadas estão disponíveis na secção ESTÁGIOS do nosso site, onde poderá encontrar todos os detalhes sobre as empresas que pretende consultar.",
    ],
    [
      "Onde decorrerá o evento?",
      "O evento terá lugar no dia 9 de abril no Departamento de Engenharia Informática da Universidade de Coimbra.",
    ],
  ];

  const estagios = [
    {
      Accenture: [
        {
          img: accenture,
          link: "https://www.accenture.com/pt-pt/careers/jobdetails?id=R00226576_pt&title=Technology%20Graduate%20Programme%20-%20Coimbra",
          description: null,
          tipo: null,
        },
      ],
    },
    {
      Adecco: [
        {
          img: adecco,
          link: null,
          description: "BOLSA ADECCO - IT (NORTE DO PAÍS)",
          tipo: null,
        },
      ],
    },
    {
      Deloitte: [
        {
          img: deloitte,
          link: "https://www.deloitte.com/pt/pt/careers/explore-your-fit/students/u-deloitte.html",
          description: null,
          tipo: "ESTÁGIOS DE VERÃO",
        },
      ],
    },
    {
      GoodBarber: [
        {
          img: goodbarber,
          link: "https://drive.google.com/file/d/10cMLM7NuCkooqjPCSTGpmz4AHAxCyxXb/view",
          description: null,
          tipo: null,
        },
      ],
    },
    {
      "Inova-Ria & Altice Labs": [
        { img: inovaAltice, link: "https://inova-ria.pt/talento/" },
        { img: altice, link: "https://www.alticelabs.com/" },
        {
          img: altice,
          link: "https://www.alticelabs.com/careers/opportunities/",
        },
        {
          img: inovaAltice,
          link: "https://drive.google.com/file/d/11QpX1taRw-4vWM_EembB97oYuX00lJWu/view",
        },
      ],
    },
    {
      sisint: [
        {
          img: sisint,
          link: "https://sisint.cvw.io/?lang=pt-PT&section=95494487-ce92-3d89-9748-f547ee794552",
          description: null,
          tipo: null,
        },
      ],
    },
    {
      tuu: [
        {
          img: tuu,
          link: null,
          description: "Estágios profissionais iefp civl (nivel 7 - mestrado)",
          tipo: null,
        },
        {img: tuu, link: null, description: "Arquitetura, Engenharia do ambiente, engenharia civil, engenharia eletrotécnica, engenharia mecânica - mediante disponibilidade e necessidade", tipo: "Estágios Curriculares"},
        {img: tuu, link: null, description: "Link de pré-inscrição disponível, inscrições abrem oficialmente no final de abril/início de maio - todas as áreas", tipo:"academia tuu/estágio de verão"},
        
      ],
    },
    {
      Worten: [
        {
          img: worten,
          link: "https://careers.worten.pt/pt/estagios",
          description: null,
          tipo: null,
        },
      ],
    },
  ];

  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={<Homepage props={people} questions={questions} />}
        />
        <Route path="/partners" element={<Partners images={partners} />} />

        <Route
          path="/internships"
          element={<Internships filters={filters} estagios={estagios} />}
        />
        <Route
          path="/previousEditions"
          element={
            <PreviousEditions images={images} testemunhos={testemunhos} />
          }
        />
      </Routes>
    </Router>
  );
}

export default App;
