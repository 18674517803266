import "../NavbarMobile/NavbarMobile.css"
import Wlogo from "../../../imgs/logo.svg"
import { useState } from "react"
import { Link } from 'react-router-dom'; 
import hamburger from "../../../imgs/hamburger.svg"
import close from "../../../imgs/closeX.svg"
import bolasNavbar from "../../../imgs/BolasNavbar.svg"

const NavbarMobile = () => {
    const [menu, setMenu] = useState(false);

    const handleRegistration = () => {
        window.location.href = process.env.REACT_APP_REGISTER_FORM;
    }
    
    const handleMainPage = () => {
        window.location.href = process.env.REACT_APP_HOME_PAGE;
    }

    const handleMenu = () => {
        setMenu(!menu);
    }

    return (
        <>
            {menu === false ? (
            <div className="mobile-display">
                <div className="navbar-container-mobile">
                    <img src={Wlogo} className="Logo-navbar-mobile" alt=''onClick={handleMainPage} />
                    <img src={hamburger} onClick={handleMenu} alt='' />
                </div>
                
            </div>) :
                (
                    <div className="mobile-display-theme">
                        <div className="navbar-container-mobile-theme">
                            <img src={close} onClick={handleMenu} alt=''/>
                        </div>
                        <div className="options-container-mobile options-container-text-mobile">
                            <Link
                                to="/previousEditions"
                                className={`buttons-container-a edicoes-passadas`}
                                >
                                EDIÇÕES PASSADAS
                            </Link>
                            <Link
                                to="/internships"
                                className={`buttons-container-a estagios`}
                                >
                                ESTÁGIOS
                            </Link>
                            <Link
                                to="/partners"
                                className={`buttons-container-a parceiros`}
                                >
                                PARCEIROS
                            </Link>
                            <Link 
                                onClick={handleMenu}
                                to="/#faqs"
                                className={`buttons-container-a faq` }
                                >
                                FAQ
                            </Link>
                            <Link
                                to="/"
                                className={`buttons-container-a perfil`}
                                >
                                Perfil
                            </Link>
                            <div className='registrations-mobile' onClick={handleRegistration}>
                                INSCRIÇÕES
                            </div>
                        </div>
                        <img src={bolasNavbar} className="bolasNavbar" alt='' />
                    </div>
                )}
        </>
    )
}

export default NavbarMobile