import "../Caroussel/Caroussel.css"
import accenture from "../../caroussel-logos/accenture.png"
import adecco from "../../caroussel-logos/Adecco.png"
import altri from "../../caroussel-logos/altri.png"
import Celfinet from "../../caroussel-logos/Celfinet.png"
import bluepharma from "../../caroussel-logos/bluepharma.png"
import deloitte from "../../caroussel-logos/deloitte.svg"
import dspa from "../../caroussel-logos/dspa.png"
import enging from "../../caroussel-logos/enging.png"
import framederop from "../../caroussel-logos/framedrop.png"    
import goodbarber from "../../caroussel-logos/goodBarber.png"
import inovaAltice from "../../caroussel-logos/inova-ria & altice labs.png"
import ipn from "../../caroussel-logos/ipn.png"
import redlight from "../../caroussel-logos/redlight.png"
import sisint from "../../caroussel-logos/sisint.png"
import sodicentro from "../../caroussel-logos/sodicentro.png"
import tuu from "../../caroussel-logos/tuu.png"
import worten from "../../caroussel-logos/worten.png"
import Marquee from "react-fast-marquee";



const Caroussel = () => {
    return (
        <Marquee gradient={true} gradientColor="white" gradientWidth={30} speed={100} pauseOnHover={true} pauseOnClick={true}>
            <div className="caroussel">
                <img className="accenture" src={accenture} alt='accenture'/>
                <img className="adecco" src={adecco} alt='adecco'/>
                <img className="altri" src={altri} alt='altri'/>
                <img className="Celfinet" src={Celfinet} alt='Celfinet'/>
                <img className="bluepharma" src={bluepharma} alt='bluepharma'/>
                <img className="dspa" src={dspa} alt='dspa'/>
                <img className="enging" src={enging} alt='enging'/>
                <img className="framedrop" src={framederop} alt='framederop'/>
                <img className="goodbarber" src={goodbarber} alt='goodbarber'/>
                <img className="inovaAltice" src={inovaAltice} alt='inovaAltice'/>
                <img className="deloitte" src={deloitte} alt='deloitte'/>
                <img className="ipn" src={ipn} alt='ipn'/>
                <img className="redlight" src={redlight} alt='redlight'/>
                <img className="sisint" src={sisint} alt='sisint'/>
                <img className="sodicentro" src={sodicentro} alt='sodicentro'/>
                <img className="tuu" src={tuu} alt='tuu'/>
                <img className="worten" src={worten} alt='worten'/>
            </div>
        </Marquee>
    )
}

export default Caroussel