import "../Internships/Internships.css";
import Navbar from "../../components/Navbar/Navbar";
import NavbarMobile from "../../components/Navbar/NavbarMobile/NavbarMobile";
import { useState, useEffect } from "react";

import CardEstagios from "../../components/CardEstagios/card";
import estagiosImg from "../../imgs/ESTAGIOS.png";
import empresasImg from "../../imgs/EMPRESAS.png";
import Footer from "../../components/Footer/Footer";
import bolasEstagios from "../../imgs/bolasEstagios.png";
import bolasEstagiosMobileLeft from "../../imgs/bolasEstagiosEsquerda.png";
import bolasEstagiosMobileRight from "../../imgs/bolasEstagiosDireita.png";

const Internships = ({ filters, estagios }) => {
  // Fixed destructuring
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 770 ? 1 : 3);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 770 ? 1 : 3);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="internships-webpage">
      {isMobile === 1 ? <NavbarMobile /> : <Navbar />}
      <div className="EstagiosTitle">
        <img src={estagiosImg} alt="Estágios" />
      </div>
      <div className="titleContainer">
        <img src={empresasImg} alt="Empresas" className="empresasImg" />
      </div>

      <div className="internshipsContainer">
        <img src={bolasEstagios} alt="Bolas" className="bolasEstagios" />
        <img src={bolasEstagiosMobileLeft} alt="Bolas" className="bolasEstagiosMobile bolasEstagiosMobileLeft" />
        <img src={bolasEstagiosMobileRight} alt="Bolas" className="bolasEstagiosMobile bolasEstagiosMobileRight" />

        {estagios.map((internshipGroup) =>
          Object.entries(internshipGroup).map(([companyName, internships,index]) => (
            <div key={index} className="estagio">
              <h1 key={index}>{companyName}</h1>
              <div key={index} className="manyEstagiosContainer" id={companyName}>
              {internships.map((internship, index) => (
                <CardEstagios
                  key={index}
                  tipoEstagio={internship.tipo}
                  img={internship.img}
                  link={internship.link}
                  descricao={internship.description}
                />
              ))}
              </div>
            </div>
          ))
        )}
      </div>
      <Footer></Footer>
    </div>
  );
};

export default Internships;
