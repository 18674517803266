import "../Homepage/Homepage.css"

import Navbar from "../../components/Navbar/Navbar"
import NavbarMobile from "../../components/Navbar/NavbarMobile/NavbarMobile"
import Faqs from "../../components/Faqs/Faqs"
import Caroussel from "../../components/Caroussel/Caroussel"
import Footer from "../../components/Footer/Footer"

import middleWorks from "../../imgs/singleCircle.svg"
import PeopleCaroussel from "../../components/PeopleCaroussel/PeopleCaroussel"

import worksLogo from "../../imgs/worksLogoComplete.svg"
import CircleGroup2 from "../../imgs/CircleGroup2.png"
import CircleGroup1 from "../../imgs/CircleGroup1.png"
import CircleGroup1Mobile from "../../imgs/CircleGroup1-Mobile.png"
import CircleGroup2Mobile from "../../imgs/CircleGroup2-Mobile.png"
import carrousselCircle from "../../imgs/carrousselCircle.svg"
import { useState, useEffect } from "react"
import { useLocation } from "react-router-dom"

const Homepage = ({ props ,questions}) => {
    const location = useLocation();

    const handleRegistration = () => {
        window.location.href = process.env.REACT_APP_REGISTER_FORM;
    }
    const [isMobile, setIsMobile] = useState(window.innerWidth < 770 ? 1 : 3);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 770 ? 1 : 3);
            console.log(isMobile)
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [isMobile]);

    useEffect(() => {

        if(location.hash === "#faqs"){
            const faqs = document.getElementById("faqs");
            faqs.scrollIntoView({behavior: "smooth"});
        }


    },[location]);




    return (
        <div className="homepage">
            {isMobile === 1 ? <NavbarMobile /> : <Navbar />}
            <div className='under-nav'>
                <img className="logo-under-nav" src={worksLogo} alt="logo" />
                <div className='texto-dali'>
                    9 de Abril
                </div>
                <div className="inscricao-boxx-container">
                    <div className='inscricao-boxx' onClick={handleRegistration}>
                        INSCREVE-TE
                    </div>
                </div>
            </div>


            <div className="middle-element">
                <img className="middle-element-circle" src={middleWorks} alt="" />
                <div className="middle-element-text">
                    <div className="tittle-text">
                        VEM MARCAR PONTOS CONNOSCO
                    </div>
                    <div className="middle-element-text-2">
                        Gostavas de ter um estágio este verão mas já deixaste passar todos os períodos de candidaturas? Ou simplesmente nenhum de interessou?
                        Ou estás a preparar-te para o teu próximo ano letivo e gostarias de fazer um estágio curricular? Estás a acabar o curso e não sabes onde fazer o teu primeiro estágio profissional?
                        <br /><br />
                        .works é um evento organizado pela jeKnowledge que
                        junta diversas empresas das áreas tecnológicas! Terás ainda
                        a oportunidade de ter entrevistas informais com as tuas preferidas!
                    </div>
                </div>
            </div>
            <Caroussel />
            <div className="under-caroussel">
                <div className="imgs-left-caroussel">
                    <img className="circleGroup1" src={CircleGroup1} alt="group1"></img>
                    <img className="circleGroup1Mobile" src={CircleGroup1Mobile} alt="group1"></img>
                </div>

                <div className="under-caroussel-text tittle-text">
                    ENCONTRA TODOS OS ESTÁGIOS
                    QUE TEMOS PARA TE OFERECER
                </div>

                <div className="imgs-right-caroussel">
                    <img className="circleMobile3" src={carrousselCircle} alt="circleMobile"></img>
                    <img className=" circleGroup2" src={CircleGroup2} alt="group2"></img>
                    <img className="circleGroup2Mobile" src={CircleGroup2Mobile} alt="group2"></img>
                </div>
            
            </div>
            <div className="people-carroussel-container">
                <img className="carroussel-top-circle" src={carrousselCircle} alt="circle"></img>
                <PeopleCaroussel people={props} />
                <img className="carroussel-bottom-circle" src={carrousselCircle} alt="circle"></img>
            </div>
            
            <Faqs questions={questions}/>
            <Footer/>
        </div>
    )
}

export default Homepage
