import "../FaqsDropdown/FaqsDropdown.css"
import { useState } from "react";
import setaDownPink from "../../../imgs/downArrowPink.svg"
import setaDownPurple from "../../../imgs/downArrowPurple.svg"


const FaqsDropdown = ({questions}) => {
     
    const [openQuestion, setOpenQuestion] = useState(null);

    const toggleDropdown = (index) => {
        setOpenQuestion(openQuestion === index ? null : index);
    };

    return (
        <div className="faqs-dropdown-container">
            {questions.map((question, index) => (
                <div className="faqs-box" key={index} onClick={() => toggleDropdown(index)}>
                    <div className="faq-inside">
                        <div className="faq-question">
                            {question[0]}
                        </div>
                        {openQuestion === index && (
                            <div className="faq-answer">
                                {question[1]}
                            </div>
                        )}
                    </div>
                    <div>
                        <img src={index % 2 === 0 ? setaDownPink : setaDownPurple} alt="seta" className={`seta ${openQuestion === index ? 'seta-rotate' : ''}`} />
                    </div>
                </div>
            ))}
        </div>
    )
}

export default FaqsDropdown