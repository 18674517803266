import '../Footer/Footer.css'
import jek from '../../imgs/jek-logo.svg'


const Footer = () => {

    const handleJekImg = () => {
        window.location.href = 'https://www.jeknowledge.pt/';
    }

    return (
        <div className='footer-container'>
            <div className='contacts-text'>
                Contactos
            </div>
            <div className='footer-inside-container'>
                <div className='info-display info-text'>
                    <div>
                        +351 925 293 082 <br />
                        dotworks@jeknowledge.com
                    </div>
                    <div>
                        jeKnowledge Associação <br />
                        Rua Larga <br />
                        Departamento de Física, B3 <br />
                        P-3004-516 Coimbra <br />
                    </div>
                </div>
                <div className='info-display info-text'>
                    <div>
                        geral@jeknowledge.com <br />
                        +351 913 198 301
                    </div>
                    <div>
                        This website is © jeKnowledge 2025  <br />
                    </div>
                    <div>
                        <img src={jek} alt='' onClick={handleJekImg} className='jek-logo' />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer;