import Navbar from "../../components/Navbar/Navbar";
import NavbarMobile from "../../components/Navbar/NavbarMobile/NavbarMobile";
import { useState, useEffect } from "react";
import Footer from "../../components/Footer/Footer";

import "../Partners/Partners.css"

import parceiros from "../../imgs/PARCEIROS.svg"
import leftImg from "../../imgs/partners-left.png"
import rightImg from "../../imgs/partners-right.png" 

const Partners = ({ images }) => {

    console.log(images)
    const [isMobile, setIsMobile] = useState(window.innerWidth < 770 ? 1 : 3);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 770 ? 1 : 3);
        };

        window.addEventListener('resize', handleResize);

        // Limpa o listener do evento de redimensionamento quando o componente é desmontado
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div className="homepage-partners">
            <img src={leftImg} alt="" className="left-img left-img1"></img>
            <img src={leftImg} alt="" className="left-img left-img2"></img>
            <img src={leftImg} alt="" className="left-img left-img3"></img>
            <img src={rightImg} alt="" className="right-img right-img1"></img>
            <img src={rightImg} alt="" className="right-img right-img2"></img>
            <img src={rightImg} alt="" className="right-img right-img3"></img>

            {isMobile === 1 ? <NavbarMobile /> : <Navbar />}
            <div className="partners-title">
                    <img src={parceiros} alt="" className="parceiros-img"></img>
                </div>
            <div className="partners-container">
                    {images.map((img, index,index2) => (
                        <div key={index}>
                            <img key={index2} src={img} alt='' />
                        </div>
                    ))
                    }

            </div>
            <Footer />
        </div>
    )
}

export default Partners;