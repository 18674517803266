import "../Testemunhos/Testemunhos.css"

const Testemunhos = ({ testemunhos }) => {

    return (

        <div className="testemunhos-container">
            {testemunhos.map((testemunho, index) => (
                <div className="testemunho" key={index}>
                    <div className={`testemunho-year year-color-${index % 3}`}>
                        {testemunho.ano}
                    </div>
                    <div className="testemunho-text">
                        {testemunho.texto}
                    </div>
                </div>
            ))
            }
        </div>
    )
}

export default Testemunhos