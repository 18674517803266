import '../Navbar/Navbar.css'
import { Link } from 'react-router-dom'; 
import logo from '../../imgs/logo.svg'
const Navbar = () => {


    const handleRegistration = () => {
        window.location.href = process.env.REACT_APP_REGISTER_FORM;
    }

    const handleMainPage = () => {
        window.location.href = process.env.REACT_APP_HOME_PAGE;
    }

    return (
        <div>
            <div className="navbar-container">
                <img className="logo" src={logo} alt="logo" onClick={handleMainPage} />
                <div className='buttons-container'>
                    <Link
                        to="/previousEditions"
                        className={`buttons-container-a edicoes-passadas`}
                        >
                        EDIÇÕES PASSADAS
                    </Link>
                    <Link
                        to="/internships"
                        className={`buttons-container-a estagios`}
                        >
                        ESTÁGIOS
                    </Link>
                    <Link
                        to="/partners"
                        className={`buttons-container-a parceiros`}
                        >
                        PARCEIROS
                    </Link>
                    <Link
                        to="/#faqs"
                        className={`buttons-container-a faq` }>
                        FAQ
                    </Link>
                </div>
                <div 
                    className='registrations' onClick={handleRegistration}>
                    INSCRIÇÕES
                </div>
            </div>
        </div>
    )
}

export default Navbar;