import "../PreviousEditions/PreviousEditions.css"
import Navbar from "../../components/Navbar/Navbar"
import NavbarMobile from "../../components/Navbar/NavbarMobile/NavbarMobile"
import { useEffect,useState } from "react"
import PastYearsCaroussel from "../../components/PastYearsCaroussel/PastYearsCaroussel"
import Testemunhos from "../../components/Testemunhos/Testemunhos"
import Bolas1 from "../../imgs/BolasEdicoesPassadas1.svg"
import Bolas2 from "../../imgs/BolasEdicoesPassadas2.svg"
import Footer from "../../components/Footer/Footer"
import Bolas3 from "../../imgs/BolasEdicoesPassadas3.svg"

const PreviousEditions = ({images,testemunhos}) => {

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 900 ? 1 : 3);
    console.log(images)
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 770 ? 1 : 3);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div className="previous-page">
            {isMobile === 1 ? <NavbarMobile /> : <Navbar />}
            <PastYearsCaroussel images={images}/>
            <Testemunhos testemunhos={testemunhos}/>
            <img src={Bolas1} alt='' className="bolas1"/>
            <img src={Bolas2} alt='' className="bolas2"/>
            <img src={Bolas3} alt='' className="bolas3"/>
            <Footer/>
        </div>
    )
}

export default PreviousEditions