import "../Faqs/Faqs.css"
import CircleGroup3 from "../../imgs/CircleGroup3.png"

import FaqsDropdown from "./FaqsDropdown/FaqsDropdown"


const Faqs = ({questions}) =>{
    return(
        <section id="faqs" className="faqs-container">
            <div className="title-container">
                <div className="faqs-text">
                    E COMO FORAM AS 
                    EDIÇÕES ANTERIORES?
                </div>
            </div>
            <div className="down-container">
                <div className="faqs-dropdowns">
                    <div className="faqs-text2">
                        FAQ
                    </div>
                    <FaqsDropdown questions={questions}/>
                </div>
                <div className="ok">
                    <img className="circleGroup3" src={CircleGroup3} alt="group3" />
                </div>
            </div>

        </section>
    )
}

export default Faqs